import React from "react";

function Footer2() {
  return (
    <div className=" flex justify-center items-center bg-black h-[45px] text-center text-white font-medium">
      <div>©2023 Suka Suka Tour. All rights reserved.</div>
    </div>
  );
}

export default Footer2;
