import React from "react";
import experience1 from "./img/experience1.png";

function Experience() {
  return (
    <div className="flex items-center justify-center  mx-[25px] lg:mx-[101px] ">
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-between ">
        <img
          className="object-cover"
          src={experience1}
          alt=""
          data-aos="fade-right"
        />
        <div className="flex flex-col ml-2 text-start" data-aos="fade-left">
          <div>
            <h5 className="font-montserrat font-bold text-xl lg:text-4xl dark:text-white mt-[38px] lg:mt-auto mb-[14px]">
              With Suka Suka Tour we have experience
            </h5>
            <h5 className="font-montserrat text-base text-black2 mt:[14px]">
              For 10 years we have been serving trips to Bromo, Bali. Many
              tourists started to become interested in climbing with us, so we
              opened the Bromo trip. Until now, we have served more than 654
              happy customers.
            </h5>
          </div>
          <div className="grid grid-cols-3 grid-rows-2 gap-1 mt-[36px] pr-0 lg:pr-[250px]">
            <div className="font-montserrat font-bold text-[38px]  h-[57px] lg:w-[104px] sm:w-[52px]">
              10
            </div>
            <div className="font-montserrat font-bold text-[38px] h-[57px] lg:w-[104px] sm:w-[52px]">
              654
            </div>
            <div className="font-montserrat font-bold text-[38px]  h-[57px] lg:w-[104px] sm:w-[52px]">
              1k+
            </div>
            <div className="font-montserrat sm:text-xs lg:text-base  lg:h-[57px] lg:w-[104px] sm:w-[52px]">
              Years Experience
            </div>
            <div className="font-montserrat sm:text-xs lg:text-base  lg:h-[57px] lg:w-[104px] sm:w-[52px]">
              Camping Tours
            </div>
            <div className="font-montserrat sm:text-xs lg:text-base  lg:h-[57px] lg:w-[104px] sm:w-[52px]">
              Happy Customer
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
