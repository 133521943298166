import React from "react";
import bookbtn from "../img/Buttonbook.png";
import imgdetail2 from "../img/detail2.png";

const Framedetail2 = () => {
  return (
    <div
      className=" h-full  mx-[35px] lg:mx-[101px] mb-[68px] lg:mb-[100px]"
      data-aos="fade-up"
    >
      <img src={imgdetail2} className=" w-full object-cover pb-[16px]" />
      <div className="text-bold text-xl lg:text-[32pt] mb-[16px]">
        BROMO SUNRISE AND IJEN CRATER TOUR (3D 2N)
      </div>
      <div className="flex flex-col gap-[24px]">
        <div className="text-medium text-base text-black2 ">
          Start From Yogyakarta drop to Ketapang Harbour
        </div>
        {/* section 1 start */}
        <div>
          <div className="font-bold text-primary">DAY 1</div>
          <div className="font-bold text-primary">BY TRAIN</div>
          <div className="font-bold text-primary">
            FROM JOGJA TO PROBOLINGGO STATION - BROMO
          </div>
          <div className="font-bold text-black2 text-sm xl:text-base">
            ECONOMY CLASS
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave from : Lempuyangan Station (9 hours)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Pick up at hotel : 05.30 - 06.00 am (Sri Tanjung)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Pick up at hotel : 07.30 - 08.00 am (Logawa)
          </div>
        </div>
        {/* section 1 end */}
        {/* section 2 start */}
        <div>
          <div className="font-bold text-black2 text-sm xl:text-base">
            EXECUTIVE & BUSSINES CLASS
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave from : Tugu Station (‡ 8 hours)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Pick up at hotel: 10.00 - 10.30 am (Rangga Jati)
          </div>
        </div>
        {/* section 2 end */}
        {/* section 3 start */}
        <div>
          <div className="font-bold text-primary">DAY 2</div>
          <div className="font-bold text-primary">
            TO PENANJAKAN SUNRISE VIEWPOINT
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            NGADISARI pick up by JEEP : 03.15 am (60 minutes)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            CEMORO LAWANG pick up by JEEP : 03.30 am (45 minutes)
          </div>
        </div>
        {/* section 3 end */}
        {/* section 4 start */}
        <div>
          <div className="font-bold text-primary">TO MT.BROMO CALDERA</div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            From PENANJAKAN leave at 06.30 am by JEEP (= 15 minutes)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            JEEP stay on parking area and continue by WALK ON FOOT
          </div>
        </div>
        {/* section 4 end */}
        {/* section 5 start */}
        <div>
          <div className="font-bold text-primary text-sm xl:text-base">
            TO CEMORO LAWANG / NGADISARI
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            From MT.BROMO leave at 08,00 am to CEMORO LAWANG by JEEP (15
            minutes)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            to NGADISARI by JEEP (30 minutes)
          </div>
        </div>
        {/* section 5 end */}

        {/* section 6 start */}
        <div>
          <div className="font-bold text-primary text-sm xl:text-base">
            DAY 3DAY 3
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 04.30 am, start trekking (1.5 hours) to the top of IJEN CRATER,
            to see Blue Lake and amazing views surroundings
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 08.30 am, back down to Paltuding Post and drive back to Hotel in
            BANYUWANGI, take shower preparing and breakfast At 11.00 am,
            checkout hotel and drive continue to Ketapang Harbour
          </div>
        </div>
        {/* section 6 end */}

        {/* section 7 start */}
        <div>
          <div className="flex flex-col gap-4">
            <div className="font-medium text-black2 text-sm xl:text-base">
              BY TRAIN (JOGJA TO PROBOLINGGO)
            </div>
            <div className="font-medium text-black2 text-sm xl:text-base">
              TOUR PACKAGE TYPE (minimum 2 person) SHARING TOUR PROGRAM
            </div>
            {/* <div className="font-medium text-black2 text-sm xl:text-base">
              INCLUDE : Pick up services, Train Tickets, Accommodation, Jeep
              Pananjakan
            </div> */}
            <div className="font-medium text-black2 text-sm xl:text-base">
              NOTE : Without jeep services to punanjakan the price will reduce
              IDR 100.000/Person
            </div>
          </div>
        </div>
        {/* section 7 end */}

        <a>
          <img src={bookbtn}></img>
        </a>
      </div>
    </div>
  );
};

export default Framedetail2;
