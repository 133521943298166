import React from "react";
import borobudur__ from "./img/borobudur__.png";
import bromo__ from "./img/bromo__.png";
import candi__ from "./img/candi__.png";
import prau__ from "./img/prau__.png";

function Frame14Dua() {
  return (
    <div className="">
      <div className=" grid gap-1 grid-cols-1 lg:grid-cols-2 md:mx-[50px] sm:mx-[50px] px-6">
        <div data-aos="fade-up">
          <img
            className="float-right lg:h-[408px] h-[408px] object-cover mb-2"
            src={borobudur__}
            alt="Mountain 1"
          />
        </div>
        <div data-aos="fade-up">
          <img
            className=" lg:h-[408px] h-[408px] object-cover"
            src={bromo__}
            alt="Mountain 2"
          />
        </div>
        <div data-aos="fade-up">
          <img
            className="float-right  lg:h-[408px] h-[408px] object-cover"
            src={prau__}
            alt="Mountain 3"
          />
        </div>
        <div data-aos="fade-up">
          <img
            className=" lg:h-[408px] h-[408px] object-cover"
            src={candi__}
            alt="Mountain 4"
          />
        </div>
      </div>
    </div>
  );
}

export default Frame14Dua;
