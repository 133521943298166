import React from "react";
import frame61 from "./img/frame61.png";

function PrivateTour() {
  return (
    <div className="flex items-center justify-center   ">
      <div>
        <div className="flex flex-col lg:flex-row justify-between items-center gap-[44px]  mx-[25px] lg:mx-[101px]">
          <div
            className="flex flex-col text-start  w-auto lg:w-[715px]"
            data-aos="fade-up"
          >
            <div className="text-black2 ">
              <h5 className="font-montserrat text-4xl font-bold text-black">
                Private Tour
              </h5>
              <div className=" h-[24px] md:h-[24px] lg:h-[24px]"></div>
              <h5 className="text-xl">
                Enjoy a private travel experience with our exclusive tours.
                Flexibility in itineraries and private guides will make your
                trip an unforgettable one.
              </h5>
              <h5 className="text-white ">halo</h5>
              <ul className="list-disc ml-6 text-xl">
                <li>
                  <span class="font-bold">Determine a Schedule:</span> Choose a
                  date that works best for you.
                </li>
                <li>
                  <span class="font-bold">Activity Customization:</span>{" "}
                  Customize the activities on your personalized tour.
                </li>
                <li>
                  <span class="font-bold">Private Guide:</span> An expert guide
                  who will take you.
                </li>
              </ul>
            </div>
          </div>
          <div className=" lg:w-[479px] lg:h-[292px] ">
            <img
              className=" rounded-lg   "
              src={frame61}
              alt=""
              data-aos="fade-up"
            />
          </div>
        </div>
        <div className=" h-[24px] md:h-[24px] lg:h-[24px]"></div>
        <span className="font-bold text-xl text-black2 mx-[25px] lg:mx-[101px]">
          Choose your private tour
        </span>
        <div className=" h-[8px] md:h-[8px] lg:h-[8px]"></div>
        <div
          class=" grid grid-cols-1 lg:grid-cols-3 justify-between  text-black2 font-montserrat  font-normal pl-[25px] lg:pl-6 mx-[25px] lg:mx-[101px] gap-[38px] lg:gap-auto"
          data-aos="fade-up"
        >
          <ol className="list-decimal leading-[150%] text-xl ">
            <li className="mb-[18px]">Borobudur - Prambanan</li>
            <li className="mb-[18px]">Borobudur - Merapi Lava Tour</li>
            <li>Borobudur - Prambanan - Ijo Temple</li>
          </ol>
          <ol className="list-decimal leading-[150%] text-xl" start="4">
            <li className="mb-[18px]">Borobudur - Prambanan - Merapi</li>
            <li className="mb-[18px]">Bromo - Ijen - Sukamade</li>
            <li>Prambanan - Ijo Temple</li>
          </ol>
          <ol className="list-decimal leading-[150%] text-xl" start="7">
            <li className="mb-[18px]">Pindul Cave - Timang Beach</li>
            <li className="mb-[18px]">Madakaripura - Bromo - Ijen</li>
            <li>Malang - Tumpak Sewu - Bromo - Ijen</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default PrivateTour;
