import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import './App.css';
// import About from './Pages/About';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AboutUs from "./Pages/AboutUs";
import Destination from "./Pages/Destination";
import Detail1 from "./Pages/Detail1";
import Detail2 from "./Pages/Detail2";
import Detail3 from "./Pages/Detail3";
import Detail4 from "./Pages/Detail4";
import Detail5 from "./Pages/Detail5";
import Home from "./Pages/Home";

const App = () => {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/destination1" element={<Detail1 />} />
        <Route path="/destination2" element={<Detail2 />} />
        <Route path="/destination3" element={<Detail3 />} />
        <Route path="/destination4" element={<Detail4 />} />
        <Route path="/destination5" element={<Detail5 />} />

        {/* <Route path="/about" element= {<About/>}/> */}
      </Routes>
    </Router>
  );
};

export default App;
