import React from "react";
// import Fade from 'react-reveal/Fade';n
import services1 from "./icon/services1.png";
import services2 from "./icon/services2.png";
import services3 from "./icon/services3.png";
import services4 from "./icon/services4.png";

function OurServices() {
  return (
    <div className="mt-[106px]  scroll-smooth">
      {/* <Fade bottom> */}
      <section className=" lg:h-[340px] md:h-[1171px] sm:h-[1171px] mx-[43px] lg:mx-[100px] ">
        <div className="flex flex-col justify-start lg:justify-center items-center ">
          <div
            className="text-start lg:text-center  font-bold mb-[44px] mt-0 text-4xl "
            data-aos="fade-up"
          >
            Our Services
          </div>
          <div class="container mx-auto ">
            <div class=" flex flex-col md:flex-row justify-center items-center gap-6">
              <div
                class="flex flex-col justify-between items-center text-6xl  rounded-xl  w-[288px] h-[252px]"
                data-aos="fade-up"
              >
                <img src={services1} className=" h-[101px]" />
                <h1 className=" text-2xl text-center font-bold mt-0">
                  Comfortable Vacation
                </h1>
                <h1 className="lg:text-base text-base text-center text-black2 leading-normal">
                  We have packaged your holiday needs here without you having to
                  bother preparing.
                </h1>
              </div>
              <div
                class="flex flex-col justify-between items-center text-6xl rounded-xl  w-[288px] h-[252px]"
                data-aos="fade-up"
              >
                <img src={services2} className=" h-[101px]" />
                <h1 className=" text-2xl text-center font-bold  mt-0">
                  Various Destinations
                </h1>
                <h1 className="lg:text-base text-base text-center text-black2 leading-normal">
                  Indonesia has natural and cultural riches that are interesting
                  for you to visit.
                </h1>
              </div>
              <div
                class="flex flex-col justify-between items-center text-6xl  rounded-xl  w-[288px] h-[252px]"
                data-aos="fade-up"
              >
                <img src={services3} className=" h-[101px]" />
                <h1 className=" text-2xl text-center font-bold  mt-0">
                  Friendly Prices
                </h1>
                <h1 className="lg:text-base text-base text-center text-black2">
                  The price you pay is in accordance with the best facilities
                  you get.
                  <span className="text-white">price you pay is in acco</span>
                </h1>
              </div>
              <div
                class="flex flex-col justify-between items-center text-6xl  rounded-xl  w-[288px] h-[252px]"
                data-aos="fade-up"
              >
                <img src={services4} className=" h-[101px]" />
                <h1 className=" text-2xl text-center font-bold  mt-0">
                  Memorable Vacation
                </h1>
                <h1 className="lg:text-base text-base text-center text-black2">
                  At every tourist destination, our team is ready to capture
                  your best documentation.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </Fade> */}
    </div>
  );
}
export default OurServices;
