import React from "react";
import instagram from "./icon/instagram.png";
import twitter from "./icon/twitter-x.png";
import youtube from "./icon/youtube.png";
// import React from "react";
// import React from "react";
// import React from "react";

function Footer() {
  const iglink = "https://www.instagram.com/suka_suka_tour/";
  return (
    <footer className="flex flex-col justify-start  lg:flex-row lg:justify-between  gap-0 items-start mx-[25px] lg:mx-[100px]  border-t-2 font-montserrat lg:py-[60px]">
      <div className=" w-[313px] text-start lg:text-start mb-auto ">
      <div className=" h-[24px] md:h-[24px] lg:h-[24px]"></div>
        <div className=" font-bold text-2xl">Suka Suka Tour.</div>
        <div className=" font-medium text-sm mt-[7px] text-black2">
          Suka Suka Tour, is here to turn your trip into a special experience.
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 lg:mt-0 ">
        {/* sesi 2 start */}
        <div className="">
          <h3 className=" text-base font-bold">Menu</h3>
          <ul className="space-y-1">
            <li>
              <a className="text-sm font-medium text-black2" href="/">
                Discover
              </a>
            </li>
            <li>
              <a
                className="text-sm font-medium text-black2"
                href="/destination"
              >
                Destinasi
              </a>
            </li>
            <li>
              <a className="text-sm font-medium text-black2" href="/about">
                About Us
              </a>
            </li>
          </ul>
        </div>
        {/* sesi 2 end */}
        {/* sesi 3 start */}
        <div className=" h-[24px] md:h-[24px] lg:h-[24px]"></div>
        <div className=" ">
          <h3 className="text-base font-bold">Contact Us</h3>
          <ul className="space-y-1">
            <li>
              <a rel="noopener noreferrer" href="#">
                <div className="flex flex-row items-center gap-[11px]">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 14 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5ZM7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 12.25 7 20 7 20C7 20 0 12.25 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM7 2C5.67392 2 4.40215 2.52678 3.46447 3.46447C2.52678 4.40215 2 5.67392 2 7C2 8 2 10 7 16.71C12 10 12 8 12 7C12 5.67392 11.4732 4.40215 10.5355 3.46447C9.59785 2.52678 8.32608 2 7 2Z"
                      fill="#454545"
                    />
                  </svg>
                  <h1 className="text-sm font-medium text-black2">
                    jl prawirotaman no 20 yogyakarta
                  </h1>
                </div>
              </a>
            </li>
            <li>
              <a rel="noopener noreferrer" href="#">
                <div className="flex flex-row items-center gap-[11px]">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.8472 12.8554L13.4306 10.8764L13.4184 10.8707C13.1892 10.7727 12.939 10.7333 12.6907 10.7562C12.4424 10.7792 12.2037 10.8636 11.9963 11.002C11.9718 11.0181 11.9484 11.0357 11.9259 11.0545L9.64407 12.9998C8.19845 12.2976 6.70595 10.8164 6.00376 9.3895L7.95188 7.07294C7.97063 7.0495 7.98845 7.02606 8.00532 7.00075C8.14072 6.79384 8.22287 6.55667 8.24446 6.31035C8.26605 6.06402 8.22641 5.81618 8.12907 5.58887V5.57762L6.14438 1.15356C6.0157 0.856621 5.79444 0.609262 5.51362 0.448409C5.2328 0.287556 4.9075 0.221836 4.58626 0.26106C3.31592 0.428223 2.14986 1.05209 1.30588 2.01615C0.461903 2.98021 -0.00228837 4.21852 8.4831e-06 5.49981C8.4831e-06 12.9436 6.05626 18.9998 13.5 18.9998C14.7813 19.0021 16.0196 18.5379 16.9837 17.6939C17.9477 16.85 18.5716 15.6839 18.7388 14.4136C18.7781 14.0924 18.7125 13.7672 18.5518 13.4864C18.3911 13.2056 18.144 12.9843 17.8472 12.8554ZM13.5 17.4998C10.3185 17.4963 7.26825 16.2309 5.01856 13.9813C2.76888 11.7316 1.50348 8.68134 1.50001 5.49981C1.49648 4.58433 1.82631 3.69887 2.42789 3.00879C3.02947 2.3187 3.86167 1.87118 4.76907 1.74981C4.7687 1.75355 4.7687 1.75732 4.76907 1.76106L6.73782 6.16731L4.80001 8.48669C4.78034 8.50932 4.76247 8.53345 4.74657 8.55887C4.60549 8.77536 4.52273 9.02462 4.5063 9.28249C4.48988 9.54037 4.54035 9.79811 4.65282 10.0307C5.5022 11.7679 7.25251 13.5051 9.00845 14.3536C9.24279 14.465 9.50203 14.5137 9.76083 14.495C10.0196 14.4762 10.2692 14.3907 10.485 14.2467C10.5091 14.2305 10.5322 14.2129 10.5544 14.1942L12.8334 12.2498L17.2397 14.2232H17.25C17.1301 15.1319 16.6833 15.9658 15.9931 16.5689C15.3028 17.172 14.4166 17.5029 13.5 17.4998Z"
                      fill="#454545"
                    />
                  </svg>

                  <h1 className="text-sm font-medium text-black2">
                    +6281215113443
                  </h1>
                </div>
              </a>
            </li>
            <li>
              <a rel="noopener noreferrer" href="#">
                <div className="flex flex-row items-center gap-[11px]">
                  <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
                      fill="#454545"
                    />
                  </svg>

                  <h1 className="text-sm font-medium text-black2">
                    toursukasuka@gmail.com
                  </h1>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {/* sesi 3 end */}
      </div>
      {/* sesi 4 start */}
      <div className=" mb-auto">
        <div className="  mt-4 lg:mt-0 font-bold">Social media</div>
        <div className="flex justify-start space-x-3">
          <a href={iglink} target="_blank" rel="noopener noreferrer">
            <img src={instagram} />
          </a>
          <button>
            <img src={twitter} />
          </button>
          <button>
            <img src={youtube} />
          </button>
        </div>
      </div>
      <div className=" h-[24px] md:h-[24px] lg:h-[24px]"></div>
      {/* sesi 4 end */}
    </footer>
  );
}

export default Footer;
