import React from "react";
import scooters1 from "./img/scooters1.png";
import scooters2 from "./img/scooters2.png";
import scooters3 from "./img/scooters3.png";

function Scooters() {
  return (
    <div className=" flex flex-col items-center justify-center gap-[44px] mx-[35px]">
      <div className="self-stretch flex flex-col items-center justify-center">
        <div
          className=" flex flex-col items-center justify-center gap-[24px]"
          data-aos="fade-up"
        >
          <div className=" text-4xl font-bold">We Also Have Scooters</div>
          <div className="font-medium text-black2">
            With affordable prices, scooter rental will make your holiday
            easier, more comfortable and more flexible.
          </div>
        </div>
      </div>
      <div
        className="flex flex-col lg:flex-row items-start justify-center gap-[20px] "
        data-aos="fade-up"
      >
        <div className="self-stretch relative h-52">
          <img
            className=" top-[0px] left-[0px] w-[303px] h-52 object-cover"
            alt=""
            src={scooters1}
          />
        </div>
        <div className="self-stretch relative h-52">
          <img
            className=" top-[0px] left-[0px] w-[303px] h-52 object-cover"
            alt=""
            src={scooters2}
          />
        </div>
        <div className="self-stretch relative h-52">
          <img
            className=" top-[0px] left-[0px] w-[303px] h-52 object-cover"
            alt=""
            src={scooters3}
          />
        </div>
      </div>
    </div>
  );
}

export default Scooters;
