import React from "react";
import janet from "./img/janet.png";
import jonathan from "./img/jonathan.jpg";
import sally from "./img/sally.png";

function Space374() {
  return (
    <div className="">
      <div
        className="flex flex-col bg-white m-auto p-auto mt-[95px]"
        data-aos="fade-up"
      >
        <h1 className="text-center  font-montserrat font-bold text-4xl py-[33px] lg:py-[498,5px]">
          What They Say ?
        </h1>

        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap gap-2 lg:ml-40 md:ml-20 ml-10">
            {/* start */}
            <div className="max-w-full lg:flex ">
              <div className=" w-[542px] h-[346px] bg-[#F6F6F6] border-r border-b border-l  border-gray-400 lg:border-l-1 lg:border-t lg:border-gray-400 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal ">
                <div className="mb-8 mt-10 mr-10 ml-10 ">
                  <div className="text-gray-900 font-montserrat  font-bold text-xl mb-2">
                    Borobudur - Magelang
                  </div>
                  <p className="font-montserrat  text-gray-700 text-base">
                    My experience with Suka Suka Tour was very impressive. Our
                    guide was very knowledgeable and helped us explore the
                    natural beauty of Indonesia. We felt like we had a good
                    local guide
                  </p>
                </div>
                <div className="flex items-center  mb-10  mr-10 ml-10">
                  <img
                    className="w-10 h-10 rounded-full mr-4"
                    src={jonathan}
                    alt="Avatar of Jonathan Reinink"
                  />
                  <div className="text-sm">
                    <p className="font-montserrat font-semibold text-base text-gray-900 leading-none">
                      Jonathan Reinink
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* end */}
            <div className="max-w-full lg:flex ">
              <div className=" w-[542px] h-[346px] bg-[#F6F6F6] border-r border-b border-l  border-gray-400 lg:border-l-1 lg:border-t lg:border-gray-400 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal ">
                <div className="mb-8 mt-10 mr-10 ml-10 ">
                  <div className="text-gray-900 font-montserrat  font-bold text-xl mb-2">
                    Borobudur - Prambanan
                  </div>
                  <p className="font-montserrat  font-medium text-gray-700 text-base">
                    Suka Suka Tour provided an unforgettable experience. Our
                    guide was incredibly informative, making our journey through
                    Indonesia's natural wonders all the more enjoyable. We truly
                    felt like we were exploring with a local expert.
                  </p>
                </div>
                <div className="flex items-center  mb-10  mr-10 ml-10">
                  <img
                    className="w-10 h-10 rounded-full mr-4"
                    src={sally}
                    alt="Avatar of Jonathan Reinink"
                  />
                  <div className="text-sm">
                    <p className="font-montserrat font-semibold text-base text-gray-900 leading-none">
                      by Sally
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-full lg:flex ">
              <div className=" w-[542px] h-[346px] bg-[#F6F6F6] border-r border-b border-l  border-gray-400 lg:border-l-1 lg:border-t lg:border-gray-400 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal ">
                <div className="mb-8 mt-10 mr-10 ml-10 ">
                  <div className="text-gray-900 font-montserrat  font-bold text-xl mb-2">
                    Bromo - Ijen
                  </div>
                  <p className="font-montserrat  font-medium text-gray-700 text-base">
                    I had an amazing time with Suka Suka Tour. The tour guide
                    was not only friendly but also highly knowledgeable about
                    Indonesia's natural treasures. We were in good hands and got
                    to experience the local charm.
                  </p>
                </div>
                <div className="flex items-center  mb-10  mr-10 ml-10">
                  <img
                    className="w-10 h-10 rounded-full mr-4"
                    src={janet}
                    alt="Avatar of Jonathan Reinink"
                  />
                  <div className="text-sm">
                    <p className="font-montserrat font-semibold text-base text-gray-900 leading-none">
                      by Sally
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-full lg:flex ">
              <div className=" w-[542px] h-[346px] bg-[#F6F6F6] border-r border-b border-l  border-gray-400 lg:border-l-1 lg:border-t lg:border-gray-400 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal ">
                <div className="mb-8 mt-10 mr-10 ml-10 ">
                  <div className="text-gray-900 font-montserrat  font-bold text-xl mb-2">
                    Bromo - Ijen
                  </div>
                  <p className="font-montserrat  font-medium text-gray-700 text-base">
                    I had an amazing time with Suka Suka Tour. The tour guide
                    was not only friendly but also highly knowledgeable about
                    Indonesia's natural treasures. We were in good hands and got
                    to experience the local charm.
                  </p>
                </div>
                <div className="flex items-center  mb-10  mr-10 ml-10">
                  <img
                    className="w-10 h-10 rounded-full mr-4"
                    src={janet}
                    alt="Avatar of Jonathan Reinink"
                  />
                  <div className="text-sm">
                    <p className="font-montserrat font-semibold text-base text-gray-900 leading-none">
                      by Janet
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Space374;
