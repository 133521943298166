import { ThemeProvider } from "@material-tailwind/react";
import Buttonkembali from "../Components/buttonkembali";
import Framedetail3 from "../Components/detail3";
import CTAA from "../cta";
import Footer from "../footer";
import Footer2 from "../footer2";
import { StickyNavbar } from "../navbar";

const Detail3 = () => {
  return (
    <ThemeProvider>
      <StickyNavbar />
      <Buttonkembali />
      <Framedetail3 />
      <CTAA />
      <Footer />
      <Footer2 />
    </ThemeProvider>
  );
};

export default Detail3;
