import React from "react";
import about1 from "../img/about1.png";
import exclusive from "../img/exclusive.png";

const FrameAbout = () => {
  return (
    <div className=" h-full  mx-[35px] lg:mx-[101px] mb-[68px] lg:mb-[100px]">
      <div className=" font-montserrat font-bold my-[35px]  lg:mt-[44px] lg:mb-[44px] text-4xl text-start">
        About Us
      </div>
      <div className=" flex flex-col lg:flex-row justify-center items-center lg:items-start mx:0  ">
        <h5 className=" font-bold font-montserrat lg:ml-5 lg:mt-4 lg:mb-4 md:mt-4 md:mb-2 sm:mt-4 sm:mb-2  text-5xl block lg:hidden">
          Suka Suka Tour
        </h5>
        <div className="lg:w-[715px] w-auto h-full  my-[21px] xl:my-0">
          <img className="lg:rounded-xl" src={about1} alt="" />
        </div>
        <div class=" flex flex-col justify-between items-start  bg-opacity-50 lg:w-[504px] lg:h-[347px] ">
          <h5 className=" font-bold font-montserrat lg:ml-5 lg:mt-4 lg:mb-4 md:mt-4 md:mb-2 sm:mt-4 sm:mb-2 lg:text-5xl text-xl hidden lg:block">
            Suka Suka Tour
          </h5>
          <h5 className=" font-medium  font-montserrat lg:ml-5 lg:mt-4 lg:mb-4 md:mt-4 md:mb-2 sm:mt-4 sm:mb-2 text-xl">
            Welcome to Suka Suka Tour, our base in Yogyakarta, Indonesia. We
            offer comfortable travel to a variety of amazing destinations, all
            at affordable prices. At Suka Suka Tour, we understand the
            importance of holiday moments and are committed to creating
            unforgettable memories. Join us and start your holiday adventure!
          </h5>
        </div>
      </div>

      {/* jarak */}
      <div className="  lg:h-[100px]"></div>
      {/* jarak */}
      <div className=" flex flex-col-reverse lg:flex-row justify-center items-center  my-[68px]  ">
        <div class=" flex flex-col justify-between items-start bg-opacity-50 mt-[30px] lg:w-[714px] ">
          <h5 className="font-bold font-montserrat  lg:mt-4 lg:mb-4 md:mt-4 md:mb-2 sm:mt-4 sm:mb-2 lg:text-4xl text-xl hidden lg:block ">
            Exclusive
          </h5>
          <h5 className="font-medium  font-montserrat  lg:mt-4 lg:mb-4 md:mt-4 md:mb-2 sm:mt-4 sm:mb-2 text-xl">
            We continually improve our service every year, paying special
            attention to our customers' happiness and striving to ensure every
            trip with us is an unforgettable experience, with moments filled
            with joy and happy memories.
          </h5>
        </div>
        <div className="lg:w-[505px] lg:mr-0 lg:ml-0 lg:mt-0  sm:mt-0 sm:w-auto sm:h-full sm:mr-0 sm:ml-0 md:mr-0 md:ml-0">
          <img className="rounded-xl" src={exclusive} alt="" />
        </div>
        <h5 className=" font-bold font-montserrat lg:mt-4 lg:mb-4 md:mt-4 md:mb-2 sm:mt-4 sm:mb-2  text-5xl block  lg:hidden">
          Exclusive
        </h5>
      </div>

      {/* jarak */}
      <div className=" h-[62px] md:h-[48px] lg:h-[62px]"></div>
      

      {/* jarak */}
      {/* maps */}
      <div className=" flex flex-col lg:flex-row justify-center lg:items-start mx:0  ">
        <h5 className=" font-bold font-montserrat lg:ml-5 lg:mt-4 lg:mb-4 md:mt-4 md:mb-2 sm:mt-4 sm:mb-2  text-5xl block lg:hidden">
          Location ?
        </h5>
        <div className="  my-[21px] xl:my-0 ">
          {/*  */}
          <iframe
            className="lg:w-[715px] w-full  h-[347px]"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.04509616424684!2d110.37034650863839!3d-7.819270968016141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a57a3b209921f%3A0x8e48090392cd6de2!2sJl.%20Prawirotaman%20No.20%2C%20Brontokusuman%2C%20Kec.%20Mergangsan%2C%20Kota%20Yogyakarta%2C%20Daerah%20Istimewa%20Yogyakarta%2055153!5e0!3m2!1sid!2sid!4v1698668927611!5m2!1sid!2sid"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* //  */}
        </div>
        <div class=" lg:mx-[21px] flex flex-col justify-center items-start xl:gap-[16px]  bg-opacity-50 lg:w-[504px] lg:h-[347px]  ">
          <h5 className=" font-bold font-montserrat  lg:text-5xl text-xl hidden lg:block">
            Location ?
          </h5>
          <h5 className=" font-medium  font-montserrat  text-xl text-start">
            Our location can be found in Yogyakarta, more precisely on Jl
            Prawirotaman no 20 Yogyakarta
          </h5>
        </div>
      </div>
    </div>
  );
};

export default FrameAbout;

{
  /* <img className="rounded-xl" src={exclusive} alt="" /> */
}
