// import { ThemeProvider } from "@material-tailwind/react";
import React from "react";
import ReactDOM from "react-dom";
// import { CarouselCustomNavigation } from "./carousel";
import "./index.css";
// import { StickyNavbar } from "./navbar";
// import { OurServices } from "./services";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world!</div>,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <RouterProvider router={router}/> */}
    {/* <ThemeProvider>
      <StickyNavbar />
      <CarouselCustomNavigation/>
      
    </ThemeProvider>
    <OurServices/>
      <Space1 />
      <Frame14/>
      <Space44/>
      <Frame14Dua/>
      <Space1/>
      <Space44/>
      <Choose/>
      <Frame43/>
      <Space44/>
      <ExploreIcon/>
      <Space1/>
      <Experience/>
      <Space1/>
      <CTAA/>
      <Space1/>
      <PrivateTour/>
      <Space374/>
      <Space1/>
      <Scooters/>
      <Space1/>
      <Footer/> */}
  </React.StrictMode>,
  document.getElementById("root")
);
