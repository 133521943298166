import { Button } from "@material-tailwind/react";
import React from "react";

function Buttonkembali() {
  return (
    <div className="lg:mx-[100px] mx-[35px] mb-0">
      <a href="/destination">
        <Button
          variant="text"
          className="flex items-center gap-3 font-medium text-base shadow-2xl"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.283 3.501L6.51005 11.475C6.37352 11.6151 6.29712 11.8029 6.29712 11.9985C6.29712 12.1941 6.37352 12.3819 6.51005 12.522L14.283 20.499C14.3466 20.5643 14.4227 20.6162 14.5067 20.6517C14.5906 20.6871 14.6809 20.7054 14.772 20.7054C14.8632 20.7054 14.9534 20.6871 15.0374 20.6517C15.1214 20.6162 15.1975 20.5643 15.261 20.499C15.3919 20.3651 15.4651 20.1852 15.4651 19.998C15.4651 19.8108 15.3919 19.6309 15.261 19.497L7.95155 11.9985L15.261 4.5015C15.3914 4.36762 15.4644 4.18813 15.4644 4.00125C15.4644 3.81437 15.3914 3.63488 15.261 3.501C15.1975 3.43568 15.1214 3.38377 15.0374 3.34832C14.9534 3.31288 14.8632 3.29461 14.772 3.29461C14.6809 3.29461 14.5906 3.31288 14.5067 3.34832C14.4227 3.38377 14.3466 3.43568 14.283 3.501Z"
              fill="#454545"
            />
          </svg>
          Kembali
        </Button>
      </a>
    </div>
  );
}

export default Buttonkembali;
