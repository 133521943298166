import { ThemeProvider } from "@material-tailwind/react";
import Frame37 from "../Components/Frame37";
import CTAA from "../cta";
import Footer from "../footer";
import Footer2 from "../footer2";
import { StickyNavbar } from "../navbar";

const Destination = () => {
  return (
    <ThemeProvider>
      <StickyNavbar />
      <Frame37 />
      <CTAA />
      <Footer />
      <Footer2 />
    </ThemeProvider>
  );
};

export default Destination;
