import React from "react";
import bookbtn from "../img/Buttonbook.png";
import imgdetail3 from "../img/detail3.png";

const Framedetail3 = () => {
  return (
    <div
      className=" h-full mx-[35px] lg:mx-[101px] mb-[68px] lg:mb-[100px]"
      data-aos="fade-up"
    >
      <img src={imgdetail3} className=" w-full object-cover pb-[16px]" />
      <div className="text-bold text-xl lg:text-[32pt] mb-[16px]">
        MADAKARIPURA WATERFALL, BROMO SUNRISE & IJEN CRATER (3D 2N)
      </div>
      <div className="flex flex-col gap-[24px]">
        <div className="text-medium text-base text-black2 ">
          Start From Yogyakarta continue to Bali / Surabaya
        </div>
        {/* section 1 start */}
        <div>
          <div className="font-bold text-primary">DAY 1</div>

          <div className="font-medium text-black2 text-sm xl:text-base">
            Departing from jogja by taking the executive express train
            (Sancaka), to Mojokerto Trainstation, the journey takes
            approximately 3.5 hours, then we pick up by car to Madakaripura
            waterfall, , 2 hours enjoying the coolness and beauty of the
            waterfall, continue to Bromo to stay at a hotel around Cemoro Lawang
            / Ngadisari
          </div>
        </div>
        {/* section 1 end */}

        {/* section 2 start */}
        <div>
          <div className="font-bold text-primary">DAY 2</div>

          <div className="font-medium text-black2 text-sm xl:text-base">
            Earty morning (03.15 / 03.30 am), by Jeep to Mount Pananjakan to see
            the sunrise and the beauty of the landscape of Mount Bromo. Then by
            Jeep to Mount Bromo, from the stop you walk up the stairs to the top
            of the crater After it was enough to enjoy the beauty back to the
            hotel. Take a bath, breakfast, and preparation to continue the
            journey to Banyuwangi / Sempol Village (Tentatin) to spend 1 night
            at the Hotel. (Free of program)
          </div>
        </div>
        {/* section 2 end */}
        {/* section 3 start */}
        <div>
          <div className="font-bold text-primary">DAY 3</div>
          <div className="font-bold text-primary">
            START IJEN CRATER TREKKING
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 03.00 am, pick up by Jeep 1.5 hours drive to Paltuding Post
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 04.30 am, start trekking and takes 1.5 hours to the top of Ijen
            Crater, to see Blue Lake and Amazing views surroundings
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 08.30 am, back down to Paltuding Post and Drive Back to Hotel in
            Banyuwangi, Take a shower, preparing, and breakfast
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 11.00 am, Checkout hotel and drive continue to Ketapang Harbour
          </div>
        </div>
        {/* section 3 end */}

        {/* section 4 start */}
        <div>
          <div className="flex flex-col gap-4">
            <div className="font-medium text-black2 text-sm xl:text-base">
              BY TRAIN (JOGJA TO PROBOLINGGO)
            </div>
            <div className="font-medium text-black2 text-sm xl:text-base">
              TOUR PACKAGE TYPE (minimum 2 person) SHARING TOUR PROGRAM
            </div>

            <div className="font-medium text-black2 text-sm xl:text-base">
              NOTE : Without jeep services to punanjakan the price will reduce
              IDR 100.000/Person
            </div>
          </div>
        </div>
        {/* section 4 end */}
        <a>
          <img src={bookbtn}></img>
        </a>
      </div>
    </div>
  );
};

export default Framedetail3;
