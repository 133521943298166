import { Button } from "@material-tailwind/react";
import React from "react";
// import { useNavigate } from "react-router-dom";

function Frame14() {
  // const navigate = useNavigate();
  const whatsappLink = "https://wa.me/+6281215113443";
  // const handleButtonClick = () => {
  // Menggunakan navigate untuk mengarahkan ke path '/about'
  //     navigate("/about");
  // };
  return (
    <div className="containter w-full ">
      <div class=" flex  flex-wrap justify-between lg:mx-[100px] mx-[50px] ">
        <div
          className="font-montserrat font-bold text-4xl lg:w-[712px] lg:h-[161px] sm:h-auto md:w-[340px] w-[340px] lg:ml-1 ml-1  mb-[48px] lg:mb-0"
          data-aos="fade-right"
        >
          Prepare a valuable trip with your family with us and make your moments
          together unforgettable.
        </div>
        <div
          class=" flex flex-col justify-between items-start h-[131px] lg:w-[290px] md:w-[220px] sm:w-[220px]  "
          data-aos="fade-left"
        >
          <div className="font-montserrat font-medium text-base">
            We can prepare everything from your travel needs from start to
            finish
          </div>
          <a href="./destination">
            <Button
              variant="text"
              className="flex items-center gap-3 font-semibold text-base shadow-2xl"
            >
              <svg
                width="21"
                height="8"
                viewBox="0 0 21 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 0.58606L20.914 8.00006H0.5V6.00006H16.086L12.086 2.00006L13.5 0.58606Z"
                  fill="#191919"
                />
              </svg>
              Explore
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Frame14;

{
  /* <div class=" flex justify-between lg:ml-[100px] lg:mr-[100px] ml-[50px] mr-[50px] bg-amber-300">
                <div className='font-montserrat font-bold text-4xl lg:w-[712px] lg:h-[161px] sm:h-auto md:w-[340px] w-[340px] lg:ml-1 ml-1 bg-blue-300'>
                    Prepare a valuable trip with your family with us and make your moments together unforgettable.
                </div>
                <div class=" flex flex-col justify-between items-start h-[131px] lg:w-[290px] md:w-[220px] sm:w-[220px]  bg-teal-400">
                    <div className='font-montserrat font-medium text-base' >We can prepare everything from your travel needs from start to finish</div>
                    <button href='#' alt='#' className='bg-teal-900'>
                        <img src={exploreButton} alt="Explore Button"/>
                    </button>
</div>
                </div> */
}
