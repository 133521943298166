import React from "react";
import bookbtn from "../img/Buttonbook.png";
import imgdetail5 from "../img/detail5.png";

const Framedetail5 = () => {
  return (
    <div
      className=" h-full  mx-[35px] lg:mx-[101px] mb-[68px] lg:mb-[100px]"
      data-aos="fade-up"
    >
      <img src={imgdetail5} className=" w-full object-cover pb-[16px]" />
      <div className="text-bold text-xl lg:text-[32pt] mb-[16px]">
        JOGJA - IJEN TOUR BY TRAIN (2D 1N)
      </div>
      <div className="flex flex-col gap-[24px]">
        <div className="text-medium text-base text-black2 ">
          Start From Yogyakarta drop to Ketapang Harbour
        </div>
        {/* section 1 start */}
        <div>
          <div className="font-bold text-primary">DAY 1</div>
          <div className="font-bold text-primary">
            BY TRAIN FROM JOGJA TO BANYUWANGI STATION
          </div>

          <div className="font-bold text-black2 text-sm xl:text-base">
            ECONOMY CLASS
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave from : Lempuyangan Station (13 hours)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Pick up at hotel: 05.30 - 06.00 am
          </div>
        </div>
        {/* section 1 end */}

        {/* section 2 start */}
        <div>
          <div className="font-bold text-primary">DAY 2</div>
          <div className="font-bold text-primary">START BLUE FIRE TOUR</div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 00.30 am pick up by Jeep 1.5 hours drive to Paltuding Post At
            02.00 am start trekking and takes 1.5 hours to the top of ijen
            Crater, to see Blue Fire and Blue Lake and amazing Views
            surroundings
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 06.00 am back down to Paltuding Post and Drive back to hotel in
            banyuwangi, take a shower Preparing and breakfast
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            At 10.00 am, checkout hotel and drive continue to ketapang harbour
          </div>
        </div>
        {/* section 2 end */}
        {/* section 4 start */}
        <div>
          <div className="font-bold text-primary">PACKAGE PRICE</div>
          <div className="font-bold text-primary">
            STANDARD A PACKAGE: 750.000 / person
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            INCLUDED: 1 night accomodation with AC & private bathroom,
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            breakfast, all transportation, blue fire, local guide, mask
          </div>
        </div>
        {/* section 4 end */}
        {/* section 5 start */}
        <div>
          <div className="font-bold text-primary">
            STANDARD A PACKAGE: 1.000.000 / person
          </div>
          {/* <div className="font-medium text-black2 text-sm xl:text-base">
            INCLUDED: 1 night accomodation with AC & private bathroom
          </div> */}
          <div className="font-medium text-black2 text-sm xl:text-base">
            with hot shower, breakfast, all transportation, blue fire,
            local guide,mask
          </div>
        </div>
        {/* section 5 end */}

        <a
          href="https://wa.me/+6281215113443"
          class=" h-[24px] mt-1 mb-1 bg-transparent hover:bg-blue-500 font-montserrat text-base font-extrabold hover:text-white hover:border-transparent rounded"
        >
          <img src={bookbtn}></img>
        </a>
      </div>
    </div>
  );
};

export default Framedetail5;
