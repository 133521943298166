import React from "react";
import bookbtn from "../img/Buttonbook.png";
import imgdetail4 from "../img/detail4.png";

const Framedetail4 = () => {
  return (
    <div
      className=" h-full  mx-[35px] lg:mx-[101px] mb-[68px] lg:mb-[100px]"
      data-aos="fade-up"
    >
      <img src={imgdetail4} className=" w-full object-cover pb-[16px]" />
      <div className="text-bold text-xl lg:text-[32pt] mb-[16px]">
        TUMPAK SEWU WATERFALL, BROMO AND IJEN CRATER (4D 3N)
      </div>
      <div className="flex flex-col gap-[24px]">
        <div className="text-medium text-base text-black2 ">
          Start From Yogyakarta continue to Bali / Surabaya
        </div>
        {/* section 1 start */}
        <div>
          <div className="font-bold text-primary">DAY 1</div>
          <div className="font-bold text-primary">BY TRAIN</div>
          <div className="font-bold text-primary">
            FROM JOGJA TO PROBOLINGGO STATION
          </div>
          <div className="font-bold text-black2 text-sm xl:text-base">
            ECONOMY CLASS
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave from : Lempuyangan Station (‡ 9 hours)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Pick up at hotel : 05.30 - 06.00 am (Sri Tanjung)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Pick up at hotel : 07.30 - 08.00 am (Logawa)
          </div>
        </div>
        {/* section 1 end */}
        {/* section 2 start */}
        <div>
          <div className="font-bold text-black2 text-sm xl:text-base">
            EXECUTIVE & BUSSINES CLASS
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave from : Tugu Station (‡ 8 hours)
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Pick up at hotel: 10.00 - 10.30 am (Rangga Jati)
          </div>
        </div>
        {/* section 2 end */}
        {/* section 3 start */}
        <div>
          <div className="font-bold text-primary">DAY 2</div>
          <div className="font-bold text-primary">
            EXPLORE WATERFALL AND TRANSFER TO CEMORO LAWANG OR BROMO
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Start at 07.00 am FROM WATERFALL
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave 11.00 am
          </div>
        </div>
        {/* section 3 end */}
        {/* section 4 start */}
        <div>
          <div className="font-bold text-primary">DAY 3</div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            - on foot explore to waterfall
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            -back to hotel at 11.30am
          </div>
        </div>
        {/* section 4 end */}
        {/* section 5 start */}
        <div>
          <div className="font-bold text-black2 text-sm xl:text-base">
            BROMO SUNRISE AND DRIVE TO IJEN CRATER
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave hotel 03.30 am by Jeep to Penanjakan 04.15am
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave Penanjakan 06.30 am by Jeep to Bromo Sand Sea 07.00
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave at 07.00 continue by Trekking to Bromo Caldera 07.30
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Untill 08.30 get down back to Bromo Sand Sea 09.00
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave at 09.00 by Jeep back to Hotel
          </div>
        </div>
        {/* section 5 end */}
        {/* section 6 start */}
        <div>
          <div className="font-bold text-primary">DAY 4</div>
          <div className="font-bold text-primary">
            WHEN TREKKING AND DROP OFF AT KETAPANG HARBOUR
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave hotel 02.30 am by Jeep to Paltuding Post 03.45
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave at 04.00 am by Trekking to ljen Crater 05.30
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave at 07.30 get down back to Paltuding Post 09.00
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave at 09.00 back to Hotel 10.15
          </div>
          <div className="font-medium text-black2 text-sm xl:text-base">
            Leave at 11.00 to Ketapang Harbour 11.30
          </div>
        </div>
        {/* section 6 end */}
        {/* section 7 start */}
        <div>
          <div className="flex flex-col gap-4">
            <div className="font-medium text-black2 text-sm xl:text-base">
              BY TRAIN (JOGJA TO PROBOLINGGO)
            </div>
            <div className="font-medium text-black2 text-sm xl:text-base">
              TOUR PACKAGE TYPE (minimum 2 person) SHARING TOUR PROGRAM
            </div>
            {/* <div className="font-medium text-black2 text-sm xl:text-base">
              INCLUDE : Pick up services, Train Tickets, Accommodation, Jeep
              Pananjakan
            </div> */}
            <div className="font-medium text-black2 text-sm xl:text-base">
              NOTE : Without jeep services to punanjakan the price will reduce
              IDR 100.000/Person
            </div>
          </div>
        </div>
        {/* section 7 end */}
        <a>
          <img src={bookbtn}></img>
        </a>
      </div>
    </div>
  );
};

export default Framedetail4;
