import { Button } from "@material-tailwind/react";
import React from "react";

function ExploreIcon() {
  const whatsappLink = "https://wa.me/+6281215113443";

  return (
    <div
      className="flex justify-center mb-[50px] lg:mb-[80px]"
      data-aos="fade-up"
    >
      <a href="./destination">
        <Button
          variant="text"
          className="flex items-center gap-3 font-semibold text-base shadow-2xl"
        >
          <svg
            width="21"
            height="8"
            viewBox="0 0 21 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 0.58606L20.914 8.00006H0.5V6.00006H16.086L12.086 2.00006L13.5 0.58606Z"
              fill="#191919"
            />
          </svg>
          Explore
        </Button>
      </a>
    </div>
  );
}

export default ExploreIcon;
