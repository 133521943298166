import React from "react";
import pantai from "./img/pantai.jpg";

function CTAA() {
  const whatsappLink = "https://wa.me/+6281215113443";

  return (
    <div className="relative flex items-center">
      {/* Gambar latar belakang */}

      <img
        className=" h-[557px] lg:h-[446px] md:w-full lg:w-full object-cover "
        src={pantai}
        alt="image cta"
      />

      {/* Konten overlay */}
      <div
        className="absolute inset-0 flex flex-col justify-center items-center gap-4 sm:text-base"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      >
        <h5
          className="text-white lg:text-4xl sm:text-xl"
          style={{ fontFamily: "Montserrat", fontWeight: "bold" }}
        >
          Start Your Adventure Now!
        </h5>
        <h5
          className="text-white text-center lg:text-xl sm:text-base"
          style={{
            maxWidth: "853px",
            fontFamily: "Montserrat",
            fontWeight: "medium",
          }}
        >
          Discover exotic destinations, unforgettable experiences and memories
          you will love for a lifetime. Contact Suka Suka Tour to plan your
          trip.
        </h5>
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <button className="bg-white hover:bg-indigo-300 font-bold py-2 px-4 rounded inline-flex items-center">
            <span>Contact Us</span>
          </button>
        </a>
      </div>
    </div>
  );
}

export default CTAA;
