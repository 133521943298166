import { ThemeProvider } from "@material-tailwind/react";
import FrameAbout from "../Components/FrameAbout";
import CTAA from "../cta";
import Footer from "../footer";
import Footer2 from "../footer2";
import { StickyNavbar } from "../navbar";

const AboutUs = () => {
  return (
    <ThemeProvider>
      <StickyNavbar />
      <FrameAbout />
      <CTAA />
      <Footer />
      <Footer2 />
    </ThemeProvider>
  );
};

export default AboutUs;
