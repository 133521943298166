import { Carousel } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import hero1 from "./img/hero1.png";
import hero2 from "./img/hero2.png";
import hero3 from "./img/hero3.png";
import hero4 from "./img/hero4.png";
import hero5 from "./img/hero5.png";
import hero6 from "./img/hero6.png";
import hero7 from "./img/hero7.png";
import hero8 from "./img/hero8.png";
import herochild1 from "./img/herochild1.png";
import herochild2 from "./img/herochild2.png";
import herochild3 from "./img/herochild3.png";
import herochild4 from "./img/herochild4.png";
import herochild5 from "./img/herochild5.png";
import herochild6 from "./img/herochild6.png";
import herochild7 from "./img/herochild7.png";
import herochild8 from "./img/herochild8.png";
// import herochild1 from "./img/herochild1.png";

export function CarouselCustomNavigation() {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (activeIndex + 1) % 4;
      setActiveIndex(nextIndex);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);
  return (
    // <div style={{ marginTop: "71px" }}>
    <Carousel
      className=""
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer  transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
      {/* <!-- First item --> */}
      <div className="relative flex items-center object-cover h-[660px] ">
        <img
          src={hero1}
          alt=""
          className="h-[660px] md:w-full lg:w-full object-cover"
        />
        <div class="absolute flex flex-col items-center justify-center lg:flex-row lg:justify-between px-[88px]  lg:px-[105px] w-full ">
          <h5 className=" font-bold text-[28pt] lg:text-[86pt] text-white text-center">
            Borobudur
          </h5>
          <h5>
            <img
              src={herochild1}
              alt=""
              className="hidden lg:block w-[265px] h-[352px]"
            />
          </h5>
        </div>
      </div>
      {/* <!-- First item End --> */}

      {/* <!-- Second item --> */}
      <div className="relative flex items-center object-cover h-[660px] ">
        <img
          src={hero2}
          alt=""
          className="h-[660px] md:w-full lg:w-full object-cover"
        />
        <div class="absolute flex flex-col items-center justify-center lg:flex-row lg:justify-between px-[88px]  lg:px-[105px] w-full ">
          <div className="  font-bold text-[28pt] lg:text-[86pt] text-white text-center">
            PRAMBANAN
          </div>
          <div>
            <img
              src={herochild2}
              alt=""
              className="hidden lg:block w-[265px] h-[352px]"
            />
          </div>
        </div>
      </div>
      {/* <!-- Second item End --> */}

      {/* <!-- Third item --> */}
      <div className="relative flex items-center object-cover h-[660px] ">
        <img
          src={hero3}
          alt=""
          className="h-[660px] md:w-full lg:w-full object-cover"
        />
        <div class="absolute flex flex-col items-center justify-center lg:flex-row lg:justify-between px-[88px]  lg:px-[105px] w-full ">
          <div className="  font-bold text-[28pt] lg:text-[86pt] text-white text-center">
            MERAPI LAVA TOUR
          </div>
          <div>
            <img
              src={herochild3}
              alt=""
              className="hidden lg:block w-[265px] h-[352px]"
            />
          </div>
        </div>
      </div>
      {/* <!-- Third item End --> */}
      {/* <!-- Fourth item --> */}
      <div className="relative flex items-center object-cover h-[660px] ">
        <img
          src={hero4}
          alt=""
          className="h-[660px] md:w-full lg:w-full object-cover"
        />
        <div class="absolute flex flex-col items-center justify-center lg:flex-row lg:justify-between px-[88px]  lg:px-[105px] w-full ">
          <h5 className=" font-bold text-[28pt] lg:text-[86pt] text-white text-center">
            SUKAMEDE
          </h5>
          <h5>
            <img src={herochild4} alt="" className="hidden lg:block" />
          </h5>
        </div>
      </div>
      {/* <!-- Fourth item End --> */}
      {/* <!-- Fiveth item --> */}
      <div className="relative flex items-center object-cover h-[660px] ">
        <img
          src={hero5}
          alt=""
          className="h-[660px] md:w-full lg:w-full object-cover"
        />
        <div class="absolute flex flex-col items-center justify-center lg:flex-row lg:justify-between px-[88px]  lg:px-[105px] w-full ">
          <h5 className=" font-bold text-[28pt] lg:text-[86pt] text-white text-center">
            TIMANG BEACH
          </h5>
          <h5>
            <img src={herochild5} alt="" className="hidden lg:block" />
          </h5>
        </div>
      </div>
      {/* <!-- Fiveth item End --> */}
      {/* <!-- Sixth item --> */}
      <div className="relative flex items-center object-cover h-[660px] ">
        <img
          src={hero6}
          alt=""
          className="h-[660px] md:w-full lg:w-full object-cover"
        />
        <div class="absolute flex flex-col items-center justify-center lg:flex-row lg:justify-between px-[88px]  lg:px-[105px] w-full ">
          <h5 className=" font-bold text-[28pt] lg:text-[86pt] text-white text-center">
            MARKADIPURA
          </h5>
          <h5>
            <img src={herochild6} alt="" className="hidden lg:block" />
          </h5>
        </div>
      </div>
      {/* <!-- Sixth item End --> */}
      {/* <!-- Seventh item --> */}
      <div className="relative flex items-center object-cover h-[660px] ">
        <img
          src={hero7}
          alt=""
          className="h-[660px] md:w-full lg:w-full object-cover"
        />
        <div class="absolute flex flex-col items-center justify-center lg:flex-row lg:justify-between px-[88px]  lg:px-[105px] w-full ">
          <h5 className=" font-bold text-[28pt] lg:text-[86pt] text-white text-center">
            TUMPAK SEWU
          </h5>
          <h5>
            <img src={herochild7} alt="" className="hidden lg:block" />
          </h5>
        </div>
      </div>
      {/* <!-- Seventh item End --> */}
      {/* <!-- Eight item --> */}
      <div className="relative flex items-center object-cover h-[660px] ">
        <img
          src={hero8}
          alt=""
          className="h-[660px] md:w-full lg:w-full object-cover"
        />
        <div class="absolute flex flex-col items-center justify-center lg:flex-row lg:justify-between px-[88px]  lg:px-[105px] w-full ">
          <h5 className="font-bold text-[28pt] lg:text-[86pt] text-white text-center">
            BLUE FIRE
          </h5>
          <h5>
            <img src={herochild8} alt="" className="hidden lg:block" />
          </h5>
        </div>
      </div>
      {/* <!-- Eight item End --> */}
    </Carousel>
    // </div>
  );
}
