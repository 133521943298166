import React from "react";
// import Fade from 'react-reveal/Fade';n
import imggrid1 from "./img/des1.png";
import imggrid2 from "./img/herochild1.png";
import services1 from "./icon/services1.png";
import services2 from "./icon/services2.png";
import services3 from "./icon/services3.png";
import services4 from "./icon/services4.png";

function Gallery() {
  return (
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div class="grid gap-4">
            <div>
                <img class="h-full w-full rounded-lg" src={imggrid1} alt=""/>
            </div>
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg" alt=""/>
            </div>
            <div>
                <img class="h-full w-full rounded-lg" src={imggrid2} alt=""/>
            </div>
        </div>
        <div class="grid gap-4">
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg" alt=""/>
            </div>
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg" alt=""/>
            </div>
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg" alt=""/>
            </div>
        </div>
        <div class="grid gap-4">
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg" alt=""/>
            </div>
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg" alt=""/>
            </div>
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg" alt=""/>
            </div>
        </div>
        <div class="grid gap-4">
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg" alt=""/>
            </div>
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg" alt=""/>
            </div>
            <div>
                <img class="h-full max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg" alt=""/>
            </div>
        </div>
    </div>   
  );
}
export default Gallery;
