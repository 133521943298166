import { ThemeProvider } from "@material-tailwind/react";
import React from "react";
// import ReactDOM from "react-dom";
import { CarouselCustomNavigation } from "../carousel";
// import Choose from "../choose";
import CTAA from "../cta";
import Experience from "../experience";
import ExploreIcon from "../exploreicon";
import Footer from "../footer";
import Footer2 from "../footer2";
import Frame14 from "../frame14";
import Frame14Dua from "../frame14dua";
import Frame43 from "../frame43";
import { StickyNavbar } from "../navbar";
import PrivateTour from "../privatetour";
import Scooters from "../scooters";
import OurServices from "../services";
import Space1 from "../space1";
import Space374 from "../space374";
import Space44 from "../space44";
import Gallery from "../gallery";
import CarouselGallery from "../carouselGallery";

// export default function Example() {
//   return <Button>Button</Button>;
// }
const Home = () => {
  return (
    <ThemeProvider>
      <StickyNavbar />
      <CarouselCustomNavigation />
      <OurServices />
      <Space1 />
      <Frame14 />
      {/* <Space44/> */}
      <Frame14Dua />
      <Space1 />
      <Space44 />
      {/* <Choose/> */}
      <Frame43 />
      {/* <Space44/> */}
      <ExploreIcon />
      {/* <Space1/> */}
      <Experience />
      <Space1 />
      <CarouselGallery/>
      <Space1 />
      <CTAA />
      <Space1 />
      <PrivateTour />
      <Space374 />
      <Space1 />
      <Scooters />
      <Space1 />
      <Footer />
      <Footer2 />
    </ThemeProvider>
  );
};
export default Home;
// ReactDOM.render(
//   <React.StrictMode>
//     <ThemeProvider>
//       <StickyNavbar />
//       <CarouselCustomNavigation/>

//     </ThemeProvider>
//     <OurServices/>
//       <Space1 />
//       <Frame14/>
//       <Space44/>
//       <Frame14Dua/>
//       <Space1/>
//       <Space44/>
//       <Choose/>
//       <Frame43/>
//       <Space44/>
//       <ExploreIcon/>
//       <Space1/>
//       <Experience/>
//       <Space1/>
//       <CTAA/>
//       <Space1/>
//       <PrivateTour/>
//       <Space374/>
//       <Space1/>
//       <Scooters/>
//       <Space1/>
//       <Footer/>
//   </React.StrictMode>,
//   // document.getElementById("root")
// );
