import React from "react";
import content_destination2 from "./img/choose2.png";
import content_destination3 from "./img/choose3.png";
import content_destination4 from "./img/choose4.png";
import content_destination1 from "./img/content_destination1.png";

function Frame43() {
  return (
    <div className="  " data-aos="fade-up">
      <div className="flex flex-col bg-white m-auto p-auto">
        <h1 className="text-center  font-montserrat font-bold text-4xl py-[31px]">
          Choose your destination
        </h1>

        <div className="flex overflow-x-scroll pb-10  lg:hide-scroll-bar ">
          <div className="flex flex-nowrap gap-2 lg:ml-40 md:ml-20 ml-10">
            {/* start */}
            <div className="max-w-full lg:flex ">
              <div class="flex flex-col items-center justify-center border-2 border-gray-300 rounded-xl p-6 lg:w-[293px] w-[284px]">
                <img
                  src={content_destination1}
                  alt="Borobudur - Prambanan"
                  className="lg:w-[256px] lg:h-150px md:w-auto md:h-max sm:w-auto sm:h-max"
                />
                <p className="font-montserrat font-bold text-base">
                  Borobudur - Prambanan
                </p>
              </div>
            </div>
            {/* end */}
            <div className="max-w-full lg:flex ">
              <div class="flex flex-col items-center justify-center border-2 border-gray-300 rounded-xl p-6 lg:w-[293px] w-[284px]">
                <img
                  src={content_destination2}
                  alt="Borobudur - Merapi Lava Tour"
                  className="lg:w-[256px] lg:h-150px md:w-auto md:h-max sm:w-auto sm:h-max"
                />
                <p className="font-montserrat font-bold text-base">
                  Borobudur - Merapi Lava Tour
                </p>
              </div>
            </div>
            <div className="max-w-full lg:flex ">
              <div class="flex flex-col items-center justify-center border-2 border-gray-300 rounded-xl p-6 lg:w-[293px] w-[284px]">
                <img
                  src={content_destination3}
                  alt="Borobudur - Prambanan - Ijo Temple"
                  className="lg:w-[256px] lg:h-150px md:w-auto md:h-max sm:w-auto sm:h-max"
                />
                <p className="font-montserrat font-bold text-base">
                  Borobudur - Prambanan - Ijo Temple
                </p>
              </div>
            </div>
            <div className="max-w-full lg:flex ">
              <div class="flex flex-col items-center justify-center border-2 border-gray-300 rounded-xl p-6 lg:w-[293px] w-[284px]">
                <img
                  src={content_destination4}
                  alt="Borobudur - Prambanan"
                  className="lg:w-[256px] lg:h-150px md:w-auto md:h-max sm:w-auto sm:h-max"
                />
                <p className="font-montserrat font-bold text-base">
                  Borobudur - Prambanan - Merapi Lava Tour
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frame43;
