import { Carousel } from 'react-carousel-minimal';
import gallery1 from "./img/gallery/gallery1.JPG";
import gallery2 from "./img/gallery/gallery2.JPG";
import gallery3 from "./img/gallery/gallery3.JPG";
import gallery4 from "./img/gallery/gallery4.JPG";
import gallery5 from "./img/gallery/gallery5.JPG";
import gallery6 from "./img/gallery/gallery6.JPG";
import gallery7 from "./img/gallery/gallery7.JPG";
import gallery8 from "./img/gallery/gallery8.JPG";
import gallery9 from "./img/gallery/gallery9.JPG";
import gallery10 from "./img/gallery/gallery10.JPG";
import gallery11 from "./img/gallery/gallery11.JPG";
import gallery12 from "./img/gallery/gallery12.JPG";
import gallery13 from "./img/gallery/gallery13.JPG";
import gallery14 from "./img/gallery/gallery14.JPG";
import gallery15 from "./img/gallery/gallery15.JPG";
import gallery16 from "./img/gallery/gallery16.JPG";
import gallery17 from "./img/gallery/gallery17.JPG";
import gallery18 from "./img/gallery/gallery18.JPG";
import gallery19 from "./img/gallery/gallery19.JPG";
import gallery20 from "./img/gallery/gallery20.JPG";

function CarouselGallery() {
 const data = [
    {
      image: gallery1,
      // caption: "Taik"
    },
    {
      image: gallery2,
      // caption: "Scotland"
    },
    {
      image: gallery3,
      // caption: "Darjeeling"
    },
    {
      image: gallery4,
      // caption: "San Francisco"
    },
    {
      image: gallery5,
      // caption: "Scotland"
    },
    {
      image: gallery6,
      // caption: "Darjeeling"
    },
    {
      image: gallery7,
      // caption: "San Francisco"
    },
    {
      image: gallery8,
      // caption: "Scotland"
    },
    {
      image: gallery9,
      // caption: "Scotland"
    },
    {
      image: gallery10,
      // caption: "Scotland"
    },
    {
      image: gallery11,
      // caption: "Scotland"
    },
    {
      image: gallery12,
      // caption: "Scotland"
    },
    {
      image: gallery13,
      // caption: "Scotland"
    },
    {
      image: gallery14,
      // caption: "Scotland"
    },
    {
      image: gallery15,
      // caption: "Scotland"
    },
    {
      image: gallery16,
      // caption: "Scotland"
    },
    {
      image: gallery17,
      // caption: "Scotland"
    },
    {
      image: gallery18,
      // caption: "Scotland"
    },
    {
      image: gallery19,
      // caption: "Scotland"
    },
    {
      image: gallery20,
      // caption: "Scotland"
    },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App" data-aos="fade-up">
      <div style={{ textAlign: "center" }}>
      <h1 className="text-center  font-montserrat font-bold text-4xl py-[33px] lg:py-[498,5px]">
          Our Gallery
        </h1>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
          thum
            data={data}
            time={2000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="100px"
            thumbnailHeight="100px"
            thumbnailImageFit="fit"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CarouselGallery;