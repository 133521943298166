import des1 from "../img/des1.png";
import des2 from "../img/des2.png";
import des3 from "../img/des3.png";
import des4 from "../img/des4.png";
import des5 from "../img/des5.png";
import paketadventure1 from "../img/paketadventure1.png";
import paketadventure2 from "../img/paketadventure2.png";
import paketadventure3 from "../img/paketadventure3.png";
import paketadventure4 from "../img/paketadventure4.png";
import paketdieng1 from "../img/paketdieng1.png";
import paketdieng2 from "../img/paketdieng2.png";
import paketdieng3 from "../img/paketdieng3.png";
import paketdieng4 from "../img/paketdieng4.png";
import paketdieng5 from "../img/paketdieng5.png";
import paketdieng6 from "../img/paketdieng6.png";
import paketmerapi1 from "../img/paketmerapi1.png";
import paketmerapi2 from "../img/paketmerapi2.png";
import paketmerapi3 from "../img/paketmerapi3.png";
import paketmerapi4 from "../img/paketmerapi4.png";
import paketwonderful1 from "../img/paketwonderful1.png";
import paketwonderful2 from "../img/paketwonderful2.png";
import paketwonderful3 from "../img/paketwonderful3.png";

const Frame37 = () => {
  const whatsappLink = "https://wa.me/+6281215113443";
  return (
    <>
      <div className="  lg:mx-[100px] mx-[35px] mb-[50px] lg:mb-[100px] ">
        <div class="grid  mx-[28px] lg:mx-0" data-aos="fade-up">
        <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
          <div class="col-fixed font-montserrat font-bold sm:text-xl lg:text-4xl ">
            WHERE ARE YOU GOING TOUR ?
          </div>
          <div class="col  h-[44px] "></div>
          <div class="col  h-[44px] font-montserrat font-bold sm:text-xl lg:text-3xl ">
            Destination
          </div>
        </div>
        <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
        {/* <div className="flex items-center bg-blue-gray-400 "> */}
        <div
          className="grid grid-cols-1 content-evenly lg:grid-cols-4 lg:gap-4 "
          data-aos="fade-up"
        >
          {/* card start */}
          <a href="/destination1">
            <div className=" w-[292px] h-[209px] mx-auto lg:mx-[14px] grid grid-cols-1 ">
              <div className="">
                <img
                  className=" h-[150px]"
                  src={des1}
                  alt="Borobudur - Prambanan"
                />
              </div>
              <div>
                <div className="font-montserrat font-bold text-base  ">
                  BROMO SUNRISE TOUR (2D 1N)
                </div>
              </div>
            </div>
          </a>
          {/* card end */}
          {/* card start */}
          <a href="/destination2">
            <div className=" w-[292px] h-[209px] mx-auto lg:mx-[14px] grid grid-cols-1 ">
              <div className="">
                <img
                  className=" h-[150px]"
                  src={des2}
                  alt="Borobudur - Prambanan"
                />
              </div>
              <div>
                <div className="font-montserrat font-bold text-base  ">
                  BROMO SUNRISE AND IJEN CRATER TOUR (3D 2N)
                </div>
              </div>
            </div>
          </a>
          {/* card end */}
          {/* card start */}
          <a href="/destination3">
            <div className=" w-[292px] h-[209px] mx-auto lg:mx-[14px] grid grid-cols-1 ">
              <div className="">
                <img
                  className=" h-[150px]"
                  src={des3}
                  alt="Borobudur - Prambanan"
                />
              </div>
              <div>
                <div className="font-montserrat font-bold text-base  ">
                  MADAKARIPURA WATERFALL, BROMO SUNRISE & IJEN CRATER
                </div>
              </div>
            </div>
          </a>
          {/* card end */}
          {/* card start */}
          <a href="/destination4">
            <div className=" w-[292px] h-[209px] mx-auto lg:mx-[14px] grid grid-cols-1 ">
              <div className="">
                <img
                  className=" h-[150px]"
                  src={des4}
                  alt="Borobudur - Prambanan"
                />
              </div>
              <div>
                <div className="font-montserrat font-bold text-base  ">
                  TUMPAK SEWU WATERFALL, BROMO AND IJEN CRATER
                </div>
              </div>
            </div>
          </a>
          {/* card end */}
          {/* card start */}
          <a href="/destination5">
            <div className=" w-[292px] h-[209px] mx-auto lg:mx-[14px] grid grid-cols-1 ">
              <div className="">
                <img
                  className=" h-[150px]"
                  src={des5}
                  alt="Borobudur - Prambanan"
                />
              </div>
              <div>
                <div className="font-montserrat font-bold text-base  ">
                  JOGJA - IJEN TOUR BY TRAIN (2D 1N)
                </div>
              </div>
            </div>
          </a>
          {/* card end */}
        </div>
        {/* </div> */}
        <div className=" h-[62px] md:h-[62px] lg:h-[62px]"></div>

        {/* PAKET TOUR */}
        <div
          class="col  mt-[44px] h-[44px] font-montserrat font-bold text-[32pt]"
          data-aos="fade-up"
        >
          Paket Tour
        </div>
        <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>

        

        <div className="h-[44px] "></div>

        {/* Wonderful Trip START */}
        <div
          class="col  mt-[0px] mb-[0px] h-[44px] font-montserrat font-medium text-2xl"
          data-aos="fade-up"
        >
          Wonderful Trip
        </div>
        {/* kolom */}
        {/* <div className="flex items-center bg-blue-gray-400 w-[1232px] ml-4"> */}
        {/* Tour Dieng Start */}
        <div
          className="grid grid-cols-1 content-evenly lg:grid-cols-3 lg:gap-4  "
          data-aos="fade-up"
        >
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketwonderful1} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold  mt-[11px]">
              Sunrise at Setumbu Hill and Borobudur Temple
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 3.30 AM</h1>
              {/* <h1>Duration : 6 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketwonderful2} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold  mt-[11px] line-clamp-3">
              Sunrise at Setumbu Hill, Borobudur and Prambanan Temple
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 3..00 AM</h1>
              {/* <h1>Duration : 10 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketwonderful3} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold  mt-[11px]">
              Enjoy Borobudur Temple, Prambanan Temple, and Sunset at Ijo Temple
            </h1>
            <div className=" h-[48px] mt-[11px] font-montserrat font-medium text-base">
              <h1>Departure : 07.00 AM</h1>
              {/* <h1>Duration : 8 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketmerapi4} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold mt-[11px]">
              Rafting at Elo RIver by Boat and Sunset Borobudur Temple
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 11.00 AM</h1>
              {/* <h1>Duration : 8 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
        </div>
        {/* Tour Dieng End */}
        {/* </div> */}
        {/* Wonderful Trip END */}

        <div className="h-[44px] "></div>

        {/* Merapi Volcano Tour START */}
        <div
        class="col  mt-[0px] mb-[0px] h-[44px] font-montserrat font-medium text-2xl"
        data-aos="fade-up"
        >
          Merapi Volcano Tour
        </div>
        {/* kolom */}
        {/* <div className="flex items-center bg-blue-gray-400 w-[1232px] ml-4"> */}
        {/* Tour Dieng Start */}
        <div
          className="grid grid-cols-1 content-evenly lg:grid-cols-3 lg:gap-4  "
          data-aos="fade-up"
        >
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketmerapi1} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold  mt-[11px]">
              Sunrise at Setumbu Hill and Borobudur Temple, and Merapi Volcano
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 3.30 AM</h1>
              {/* <h1>Duration : 10 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketmerapi2} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold mt-[11px]">
              Merapi Volcano and Prambanan Temple
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 11.00 AM</h1>
              {/* <h1>Duration : 8 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketmerapi3} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold  mt-[11px]">
              Sunrise at Merapi Volcano by Jeep
            </h1>
            <div className=" h-[48px] mt-[11px] font-montserrat font-medium text-base">
              <h1>Departure : 3.30 AM</h1>
              {/* <h1>Duration : 5 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketmerapi4} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold mt-[11px]">
              Rafting at Elo RIver by Boat and Sunset Borobudur Temple
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 11.00 AM</h1>
              {/* <h1>Duration : 8 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
        </div>
        {/* Tour Dieng End */}
        {/* </div> */}
        {/* Merapi Volcano Tour END */}

        <div className="h-[44px] "></div>

        {/* Adventure Trip START */}
        <div
          class="col  mt-[0px] mb-[0px] h-[44px] font-montserrat font-medium text-2xl"
          data-aos="fade-up"
        >
          Adventure Trip
        </div>
        {/* kolom */}
        {/* <div className="flex items-center bg-blue-gray-400 w-[1232px] ml-4"> */}
        {/* Tour Dieng Start */}
        <div
          className="grid grid-cols-1 content-evenly lg:grid-cols-3 lg:gap-4  "
          data-aos="fade-up"
        >
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketadventure1} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold  mt-[11px]">
              Rafting at Pindul Cave
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 7.00 AM</h1>
              {/* <h1>Duration : 6 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
          {/* card Tour start*/}
          {/* <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketadventure2} alt="" />
            <h1 className="h-[44px] font-montserrat text-base font-bold 0 mt-[11px]">
              Jomblang Cave
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 7.00 AM</h1>
              // <h1>Duration : 7 hour</h1>
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div> */}
          {/* card tour end */}
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketadventure3} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold  mt-[11px]">
              Jomblang Cave and Pindul Cave
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 7.00 AM</h1>
              {/* <h1>Duration : 9 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
          {/* card Tour start*/}
          <div className="  flex flex-col items-start rounded overflow-hidden   h-[412px] w-full lg:w-[395px]">
            <img className="h-[150px] mt-[14px]" src={paketadventure4} alt="" />
            <h1 className="h-[66px] lg:h-[44px] font-montserrat text-base font-bold  mt-[11px]">
              Jomblang Cave and Timang Beach
            </h1>
            <div className=" h-[48px] mt-[11px]  font-montserrat font-medium text-base">
              <h1>Departure : 7.00 AM</h1>
              {/* <h1>Duration : 10 hour</h1> */}
            </div>

            <div className=" h-[48px] md:h-[48px] lg:h-[48px]"></div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Book via Whatsapp
            </a>
          </div>
          {/* card tour end */}
        </div>
        {/* Tour Dieng End */}
        {/* </div> */}
        {/* Adventure Trip END */}
      </div>
    </>
  );
};

export default Frame37;
